import * as React from "react";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { Subtitle, SmallPrint } from "@components/Typography";
import Layout from "@components/Layout";
import Map from "@components/Maps";
import ContactBlock from "@blocks/ContactBlock";
import { graphql } from 'gatsby'

const Container = tw.div`bg-midnight`;
const MapContainer = tw.div`items-center flex w-full`;
const AddressContainer = tw.div`items-center flex w-full justify-center pt-[100px]`;

const GridContainer = tw.div`bg-midnight py-[50px] `;

const Grid = styled.div(() => [
  tw`grid grid-cols-3 grid-rows-2 gap-[50px]  max-w-[800px] self-center mx-auto`,
]);

const GridItem = styled.div(() => [tw`w-[100%] h-[100px] bg-white opacity-30`]);

// markup
const Contact = ({ data }) => {
  // console.log("data", data);
  return (
    <Layout>
      <Container>
        <MapContainer>
          <Map />
        </MapContainer>

        <AddressContainer>
          <div className="flex flex-col gap-[50px]">
            <Subtitle className="text-2xl" color="white">
              <a
                className="link-underline white"
                href="mailto:info@thinktutors.com">
                info@thinktutors.com
              </a>
            </Subtitle>
            <Subtitle className="text-2xl" color="white">
              <a className="link-underline white" href="tel:00442071172835">
                +44 (0) 207 117 2835
              </a>
            </Subtitle>
            <a href="https://goo.gl/maps/NtkYpN2UDXP7M7LL7" target="blank">
              <Subtitle className="text-2xl" color="white">
                Berkeley Square House,
              </Subtitle>
              <Subtitle className="text-2xl" color="white">
                35 Berkeley Square,
              </Subtitle>
              <Subtitle className="text-2xl" color="white">
                Mayfair,
              </Subtitle>
              <Subtitle className="text-2xl" color="white">
                London, W1J 5BF
              </Subtitle>
            </a>
          </div>
        </AddressContainer>

        <ContactBlock contactPage={true} />
      </Container>
    </Layout>
  );
};

export default Contact;



// export const pageQuery = graphql`
//   query ContactPageById(
//     # these variables are passed in via createPage.pageContext in gatsby-node.js
//     $id: String!
//   ) {
//     # selecting the current post by id
//     page: wpPage(id: { eq: $id }) {
//       id
//       content
//       title
//       seo {
//         title
//         metaDesc
//         focuskw
//         metaKeywords
//         metaRobotsNoindex
//         metaRobotsNofollow
//         opengraphTitle
//         opengraphDescription
//         opengraphImage {
//           altText
//           sourceUrl
//           srcSet
//         }
//         twitterTitle
//         twitterDescription
//         twitterImage {
//           altText
//           sourceUrl
//           srcSet
//         }
//         canonical
//         cornerstone
//         schema {
//           articleType
//           pageType
//           raw
//         }
//       }
//       isFrontPage
//       pageBlocks {
//         pageType
//         standardContent
//         pageBlocks {
//           ... on WpPage_Pageblocks_PageBlocks_Introduction {
//             content
//             fieldGroupName
//             price
//             summary {
//               point
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_LandingTabs {
//             fieldGroupName
//             tabs {
//               content
//               fieldGroupName
//               link {
//                 target
//                 url
//                 title
//               }
//               title
//               image {
//                 localFile {
//                   childImageSharp {
//                     gatsbyImageData(
//                       width: 1600
//                       placeholder: BLURRED
//                       quality: 100
//                     )
//                   }
//                 }
//               }
//             }
//             breadcrumb
//           }
//           ... on WpPage_Pageblocks_PageBlocks_Statistic {
//             fieldGroupName
//             figure
//             headline
//             textOnly
//             background {
//               localFile {
//                 childImageSharp {
//                   gatsbyImageData(
//                     width: 1600
//                     placeholder: BLURRED
//                     quality: 100
//                   )
//                 }
//               }
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_ContactBlock {
//             fieldGroupName
//           }
//           ... on WpPage_Pageblocks_PageBlocks_Insights {
//             fieldGroupName
//             title
//             relatedInsight {
//               ... on WpPost {
//                 id
//                 date
//                 title
//                 excerpt
//                 uri
//               }
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_Accordion {
//             fieldGroupName
//             items {
//               title
//               link {
//                 url
//                 title
//                 target
//               }
//               fieldGroupName
//               content
//               backgroundImage {
//                 localFile {
//                   childImageSharp {
//                     gatsbyImageData(
//                       width: 1600
//                       placeholder: BLURRED
//                       quality: 100
//                     )
//                   }
//                 }
//               }
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_ImageTextBlock {
//             alignment
//             color
//             content
//             fieldGroupName
//             subHeadline
//             subtitle
//             title
//             image {
//               localFile {
//                 childImageSharp {
//                   gatsbyImageData(
//                     width: 1200
//                     placeholder: BLURRED
//                     quality: 100
//                   )
//                 }
//               }
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_QuotePanel {
//             fieldGroupName
//             quote {
//               quoteContent
//               person
//               fieldGroupName
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_FullPanelImage {
//             fieldGroupName
//             headline
//             image {
//               localFile {
//                 childImageSharp {
//                   gatsbyImageData(
//                     width: 1200
//                     placeholder: BLURRED
//                     quality: 100
//                   )
//                 }
//               }
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_MultipleStatisticPanel {
//             fieldGroupName
//             statistic {
//               title
//               figure
//               content
//               icon {
//                 localFile {
//                   childImageSharp {
//                     # Try editing the "maxWidth" value to generate resized images.
//                     fluid(maxWidth: 1200, quality: 100) {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//             backgroundImage {
//               localFile {
//                 childImageSharp {
//                   gatsbyImageData(
//                     width: 1200
//                     placeholder: BLURRED
//                     quality: 100
//                   )
//                 }
//               }
//             }
//           }
//           ... on WpPage_Pageblocks_PageBlocks_List {
//             color
//             fieldGroupName
//             title
//             listItems {
//               content
//             }
//           }
//         }
//       }
//     }
//   }
// `;
